(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
var Faq = /** @class */ (function () {
    function Faq() {
        this.element = $('.js-faq');
        $('article').hide();
        $('article').first().show();
        $('.js-faq--topic').first().addClass('active');
        $('.js-faq--categorie').first().addClass('active');
        $('.js-accessibility').hide();
        this.topics = this.element.find('.js-faq--topics .js-faq--topic');
        this.categories = this.element.find('.js-faq--categories a');
        this.items = this.element.find('.c-faq__question');
        this.items.removeClass('active');
        var openItems;
        var openTopics;
        this.items.each(function (index, item) {
            var items = $(item);
            var openItems = new FaqItems(items);
        });
        this.topics.each(function (index, item) {
            var topic = $(item);
            var openTopics = new FaqTopics(topic);
        });
        this.categories.each(function (index, item) {
            var categorie = $(item);
            var openCategory = new FaqCategories(categorie);
        });
        $('.faq__categorie').hide();
        $('.faq__categorie').first().show();
    }
    return Faq;
}());
var FaqCategories = /** @class */ (function () {
    function FaqCategories(topic) {
        this.element = topic;
        this.element.on('click', this.open.bind(this));
    }
    FaqCategories.prototype.open = function (event) {
        event.preventDefault();
        var tab = '#' + this.element.attr('aria-controls');
        $('.faq__categorie').hide();
        $(tab).show();
        if (this.element.hasClass('active')) {
            this.element.removeClass('active');
            $(tab).hide();
        }
        else {
            $('.js-faq--categorie.active').removeClass('active');
            this.element.addClass('active');
        }
    };
    return FaqCategories;
}());
var FaqTopics = /** @class */ (function () {
    function FaqTopics(topic) {
        this.element = topic;
        this.element.find('h2').on('click', this.open.bind(this));
        this.list = this.element.find(".c-faq__questionsList");
        this.list.find(".c-faq__question").first().addClass('active');
    }
    FaqTopics.prototype.open = function (event) {
        event.preventDefault();
        var tab = '#' + this.element.attr('aria-controls');
        $('article').hide();
        $(tab).show();
        if (this.element.hasClass('active')) {
            this.element.removeClass('active');
            $(tab).hide();
        }
        else {
            $('.js-faq--topic.active').removeClass('active');
            this.element.addClass('active');
        }
    };
    return FaqTopics;
}());
var FaqItems = /** @class */ (function () {
    function FaqItems(items) {
        this.element = items;
        this.content = this.element.find('dd');
        this.element.on('mouseup', this.toggle.bind(this));
        // this.element.removeClass('active');
    }
    FaqItems.prototype.toggle = function () {
        event.preventDefault();
        if (this.element.hasClass('active')) {
            this.element.removeClass('active');
        }
        else {
            $('.c-faq__question.active').removeClass('active');
            this.element.addClass('active');
        }
    };
    return FaqItems;
}());
var faq = new Faq();
},{}],2:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./menu");
// import "./fade";
// import "./loadMore";
require("./faq");
// import  "./lightbox";
// import "./anchor";
require("./paths");
},{"./faq":1,"./menu":3,"./paths":4}],3:[function(require,module,exports){
var Menu = /** @class */ (function () {
    function Menu() {
        $('.c-nav-toggle').on('click', this.toggle.bind(this));
        $('main').on('click', this.destroy.bind(this));
        $(window).resize(this.destroy.bind(this));
        $(window).resize(this.height.bind(this));
        //  this.height();
    }
    Menu.prototype.toggle = function (event) {
        event.preventDefault();
        $('body').toggleClass('is-activeNav');
    };
    Menu.prototype.destroy = function () {
        if ($('.is-activeNav').length) {
            $('body').removeClass('is-activeNav');
        }
    };
    Menu.prototype.height = function () {
        if ($(window).width() >= 600) {
            this.menu = $('.c-nav').innerHeight();
            //  $('body').css({"padding-top": `${this.menu - 0}px`});
        }
        else if ($(window).width() <= 600) {
            $('body').css({ "padding-top": "0px" });
        }
    };
    return Menu;
}());
var menu = new Menu();
},{}],4:[function(require,module,exports){
var Paths = /** @class */ (function () {
    function Paths() {
        this.element = $(".js-infocards");
        // this.btn = this.element.find('.c-info');
        // this.btn.each((index, item) => {
        //     let btn = $(item);
        //     let newBtn = new InfoBtns(btn);
        //
        // });
        this.switchers = $("[data-btn='voorswitchers']");
        this.werkgevers = $("[data-btn='voorwerkgevers']");
        this.switchers.on('click', this.switcherEvent.bind(this));
        this.werkgevers.on('click', this.werkgeversEvent.bind(this));
    }
    Paths.prototype.switcherEvent = function (event) {
        event.preventDefault();
        this.switchers.closest(".js-infoset").addClass('animate--left');
        // this.switchers.closest(".js-infoset").addClass('is-hidden');
        $("[data-type='voorswitchers']").addClass("is-visible");
        $(".u-arrow--alt").find(".u-arrow__shadow").addClass("u-arrow__shadow--active");
    };
    Paths.prototype.werkgeversEvent = function (event) {
        event.preventDefault();
        this.werkgevers.closest(".js-infoset").addClass('animate--right');
        $("[data-type='voorwerkgevers']").addClass("is-visible");
        $(".u-arrow--alt").find(".u-arrow__shadow").addClass("u-arrow__shadow--active");
    };
    return Paths;
}());
var paths = new Paths();
},{}]},{},[2])

