class Paths {
  element: any;
  switchers: any;
  werkgevers: any;
    constructor() {
      this.element = $(".js-infocards");
      // this.btn = this.element.find('.c-info');
      // this.btn.each((index, item) => {
      //     let btn = $(item);
      //     let newBtn = new InfoBtns(btn);
      //
      // });

      this.switchers = $("[data-btn='voorswitchers']");
      this.werkgevers = $("[data-btn='voorwerkgevers']");
      this.switchers.on('click', this.switcherEvent.bind(this));
      this.werkgevers.on('click', this.werkgeversEvent.bind(this));

    }
    switcherEvent(event){
      event.preventDefault();
      this.switchers.closest(".js-infoset").addClass('animate--left');
      // this.switchers.closest(".js-infoset").addClass('is-hidden');
      $("[data-type='voorswitchers']").addClass("is-visible");
      $(".u-arrow--alt").find(".u-arrow__shadow").addClass("u-arrow__shadow--active");
    }
    werkgeversEvent(event){
      event.preventDefault();
      this.werkgevers.closest(".js-infoset").addClass('animate--right');
      $("[data-type='voorwerkgevers']").addClass("is-visible");
      $(".u-arrow--alt").find(".u-arrow__shadow").addClass("u-arrow__shadow--active");
    }
}


let paths = new Paths();
