class Faq {
    element = $('.js-faq');
    items: any;
    topics: any;
    categories: any;
  constructor() {
    $('article').hide();
    $('article').first().show();
    $('.js-faq--topic').first().addClass('active');
    $('.js-faq--categorie').first().addClass('active');
    $('.js-accessibility').hide();
    this.topics = this.element.find('.js-faq--topics .js-faq--topic');
    this.categories = this.element.find('.js-faq--categories a');

    this.items = this.element.find('.c-faq__question');
    this.items.removeClass('active');
    let openItems;
    let openTopics;
    this.items.each((index, item) => {
        let items = $(item);
        let openItems = new FaqItems(items);
    });
    this.topics.each((index, item) => {
        let topic = $(item);
        let openTopics = new FaqTopics(topic);
    });

    this.categories.each((index, item) => {
        let categorie = $(item);
        let openCategory = new FaqCategories(categorie);
    });
    $('.faq__categorie').hide();
    $('.faq__categorie').first().show();

  }
}


class FaqCategories {
  element: any;
  constructor(topic) {
    this.element = topic;
    this.element.on('click', this.open.bind(this));

  }

  open(event) {
    event.preventDefault();
    let tab = '#' + this.element.attr('aria-controls');
    $('.faq__categorie').hide();
    $(tab).show();
    if(this.element.hasClass('active')) {
      this.element.removeClass('active');
      $(tab).hide();
    }else {
        $('.js-faq--categorie.active').removeClass('active');
        this.element.addClass('active');
    }
  }

}



class FaqTopics {
  element: any;
  list: any;
  constructor(topic) {
    this.element = topic;
    this.element.find('h2').on('click', this.open.bind(this));
    this.list = this.element.find(".c-faq__questionsList");
    this.list.find(".c-faq__question").first().addClass('active');
  }

  open(event) {
    event.preventDefault();
    let tab = '#' + this.element.attr('aria-controls');
    $('article').hide();
    $(tab).show();
    if(this.element.hasClass('active')) {
      this.element.removeClass('active');
      $(tab).hide();
    }else {
        $('.js-faq--topic.active').removeClass('active');
        this.element.addClass('active');
    }
  }

}

class FaqItems {
    element: any;
    content: any;
    constructor(items) {
        this.element = items;
        this.content = this.element.find('dd');
        this.element.on('mouseup', this.toggle.bind(this));
        // this.element.removeClass('active');
    }
    toggle() {
      event.preventDefault();
        if(this.element.hasClass('active')) {
          this.element.removeClass('active');
        }else {
            $('.c-faq__question.active').removeClass('active');
            this.element.addClass('active');
        }
    }


}

let faq = new Faq();
